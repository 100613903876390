.tox .tox-dialog--width-lg {
    margin: "5%" !important;
    border-radius: 0px !important;
    height: 29.1cm !important;
    max-width: 21cm !important; 
}

.tox .tox-dialog__body-content {
 max-height: 30cm !important; 
 margin-bottom: 1% !important;
 padding: 0  !important;
}

.tox .tox-dialog-wrap {
    padding: 0.3%;
}

.tox-dialog-wrap__backdrop{
    background-color: black !important;
    opacity: 70% !important;
}

